import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Grid,
  Paper,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Draggable from "react-draggable";
import BlockDialogUI from "./BlockDialogUI";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const CommonTotalDialog = ({
  open,
  onClose,
  title,
  handleSubmit,
  children,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperComponent={PaperComponent}
      scroll="body"
      aria-labelledby="draggable-dialog-title"
      fullWidth
      maxWidth="sm"
    >
      <BlockDialogUI>
        <DialogTitle
          dividers
          style={{ backgroundColor: "#f2674a", color: "white" }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h2 className="main-content-title tx-24 mg-b-5">{title}</h2>
            <IconButton aria-label="close" onClick={onClose} size="small">
              <CloseIcon sx={{ color: "white" }} />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent dividers sx={{ backgroundColor: "#2c2c2c" }}>
          <Grid container spacing={2}>
            {children}
          </Grid>
        </DialogContent>
      </BlockDialogUI>
    </Dialog>
  );
};

export default CommonTotalDialog;
